/* eslint-disable no-unused-vars, react/no-unused-prop-types, react/prop-types, react/destructuring-assignment */
import VisibilitySensor from 'react-visibility-sensor';
import Img from 'gatsby-image';
import React from 'react';
import styledSystem from '@emotion/styled';
import { css } from '@emotion/core';
import PropTypes from 'prop-types';
import ScrollIntoView from 'react-scroll-into-view';
import { shadedPanel } from '../../style/panelStyling';
import { svgStyles } from '../../style/featherIconStyle';
import colors from '../../utils/colors';
import { Box, Flex } from '../../style/basicStyle';
import LazyHero from '../../utils/react-lazy-hero';
import media from '../../utils/media';
import feather from '../../utils/feather';
// import media from '../../utils/media';
import defaultHero from '../../../redjulyassets/images/hero.jpg';
import getImageByName from '../../utils/getImageByName';

const heroParentStyle = css`
  text-align: center;
  margin-top: 55px;
  ${media.small`
     margin-top: 0px;
  `};
`;

const header1Style = css`
  color: ${colors.heading1};
`;
const header2Style = css`
  color: ${colors.heading2};
`;

const header3Style = css`
  color: ${colors.heading3};
`;

const header4Style = css`
  color: ${colors.heading4};
`;

const underlineVar = css`
  display: block;
  position: absolute;
  margin-top: -15px;
  left: 50%;
  border-bottom: 4px solid ${colors.tertiary};
  width: 0%;
  text-align: center;
  animation: line 1.25s linear forwards;
`;

const underlineKeyFrames = css`
  @keyframes line {
    from {
      left: 50%;
      width: 0%;
    }
    to {
      left: 30%;
      width: 40%;
    }
  }
`;

const heroScroll = css`
  position: absolute;
  bottom: 55px;
  width: 200px;
  margin: auto;
  display: block;
  cursor: pointer;
  padding-bottom: 25px;
  left: 0;
  right: 0;
  text-transform: uppercase;
`;

const heroBottom = css`
  margin-top: 25px;
  display: block;
  -webkit-animation: pulse 2s infinite;
  animation: pulse 2s infinite;
  color: ${colors.tertiary};

  @-webkit-keyframes pulse {
    0% {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
    }
    50% {
      -webkit-transform: translate(0, 10px);
      transform: translate(0, 10px);
    }
    100% {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
    }
  }

  @keyframes pulse {
    0% {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
    }
    50% {
      -webkit-transform: translate(0, 10px);
      transform: translate(0, 10px);
    }
    100% {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
    }
  }
`;

// eslint-disable-next-line arrow-body-style
const Hero = (props) => {
  return (
    <div css={heroParentStyle}>
      <LazyHero
        video={props.video}
        color={props.color}
        opacity={props.opacity}
        transitionTimingFunction={props.transitionTimingFunction}
        transitionDuration={props.transitionDuration}
        parallaxOffset={100}
        imageSrc={props.heroImage.length > 0 ? props.heroImage : props.heroImageDefault}
        minHeight={props.minHeight}
      >
        {props.heading1 && props.heading1.length > 0 ? (
          <h1
            css={[header1Style, props.heading1CustomCSS]}
            data-aos={`${props['heading1-data-aos']}`}
            data-aos-delay={`${props['heading1-data-aos-delay']}`}
            data-aos-offset={`${props['heading1-data-aos-offset']}`}
            data-aos-duration={`${props['heading1-data-aos-duration']}`}
            data-aos-easing={`${props['heading1-data-aos-easing']}`}
            data-aos-mirror={`${props['heading1-data-aos-mirror']}`}
            data-aos-once={`${props['heading1-data-aos-once']}`}
            data-aos-anchor-placement={`${props['heading1-data-aos-anchor-placement']}`}
          >
            {props.heading1}
          </h1>
        ) : (
          props.heading1Object && (
            <h1
              css={[header1Style, props.heading1CustomCSS]}
              data-aos={`${props['heading1-data-aos']}`}
              data-aos-delay={`${props['heading1-data-aos-delay']}`}
              data-aos-offset={`${props['heading1-data-aos-offset']}`}
              data-aos-duration={`${props['heading1-data-aos-duration']}`}
              data-aos-easing={`${props['heading1-data-aos-easing']}`}
              data-aos-mirror={`${props['heading1-data-aos-mirror']}`}
              data-aos-once={`${props['heading1-data-aos-once']}`}
              data-aos-anchor-placement={`${props['heading1-data-aos-anchor-placement']}`}
            >
              {props.heading1Object}
            </h1>
          )
        )}
        {props.pageImages && props.heading1Image && props.heading1ImageCSS && (
          <Flex
            textAlign="center"
            justifyContent="center"
            wrap={['wrap']}
            data-aos={`${props['heading1Image-data-aos']}`}
            data-aos-delay={`${props['heading1Image-data-aos-delay']}`}
            data-aos-offset={`${props['heading1Image-data-aos-offset']}`}
            data-aos-duration={`${props['heading1Image-data-aos-duration']}`}
            data-aos-easing={`${props['heading1Image-data-aos-easing']}`}
            data-aos-mirror={`${props['heading1Image-data-aos-mirror']}`}
            data-aos-once={`${props['heading1Image-data-aos-once']}`}
            data-aos-anchor-placement={`${props['heading1Image-data-aos-anchor-placement']}`}
          >
            <Img css={props.heading1ImageCSS} fluid={getImageByName({ pageImages: props.pageImages, placeholderImage: props.placeholderImage, imageName: props.heading1Image })} />
          </Flex>
        )}
        {props.heading2 && props.heading2.length > 0 && (
          <div>
            <h2
              css={[header2Style, props.heading2CustomCSS]}
              data-aos={`${props['heading2-data-aos']}`}
              data-aos-delay={`${props['heading2-data-aos-delay']}`}
              data-aos-offset={`${props['heading2-data-aos-offset']}`}
              data-aos-duration={`${props['heading2-data-aos-duration']}`}
              data-aos-easing={`${props['heading2-data-aos-easing']}`}
              data-aos-mirror={`${props['heading2-data-aos-mirror']}`}
              data-aos-once={`${props['heading2-data-aos-once']}`}
              data-aos-anchor-placement={`${props['heading2-data-aos-anchor-placement']}`}
            >
              {props.heading2}
            </h2>
            {props.heading2Underline && props.heading2Underline.length > 0 && <div css={[underlineVar, underlineKeyFrames]} />}
          </div>
        )}
        {props.heading3 && props.heading3.length > 0 && (
          <h3
            css={[header3Style, props.heading3CustomCSS]}
            data-aos={`${props['heading3-data-aos']}`}
            data-aos-delay={`${props['heading3-data-aos-delay']}`}
            data-aos-offset={`${props['heading3-data-aos-offset']}`}
            data-aos-duration={`${props['heading3-data-aos-duration']}`}
            data-aos-easing={`${props['heading3-data-aos-easing']}`}
            data-aos-mirror={`${props['heading3-data-aos-mirror']}`}
            data-aos-once={`${props['heading3-data-aos-once']}`}
            data-aos-anchor-placement={`${props['heading3-data-aos-anchor-placement']}`}
          >
            {props.heading3}
          </h3>
        )}
        {props.heading4 && props.heading4.length > 0 && (
          <h3
            css={[header3Style, props.heading4CustomCSS]}
            data-aos={`${props['heading4-data-aos']}`}
            data-aos-delay={`${props['heading4-data-aos-delay']}`}
            data-aos-offset={`${props['heading4-data-aos-offset']}`}
            data-aos-duration={`${props['heading4-data-aos-duration']}`}
            data-aos-easing={`${props['heading4-data-aos-easing']}`}
            data-aos-mirror={`${props['heading4-data-aos-mirror']}`}
            data-aos-once={`${props['heading4-data-aos-once']}`}
            data-aos-anchor-placement={`${props['heading4-data-aos-anchor-placement']}`}
          >
            {(props.heading4.indexOf('://') > -1 && <a target="_blank" rel="noreferrer" href={`${props.heading4}`}>{props.heading5 && `${props.heading5}` || `${props.heading4}`}</a>) || props.heading4}
          </h3>
        )}
        {props.showScrollDown && (
          <ScrollIntoView selector="#projectredintro">
            <Box css={heroScroll} data-aos="fade-in" data-aos-once="true" data-aos-easing="ease" data-aos-mirror="true" data-aos-delay="800">
              <Box fontSize={[2, 3, 3]}>Scroll down</Box>
              <div css={heroBottom}>{feather('chevron-down', ['30', '30'], svgStyles.styles)}</div>
            </Box>
          </ScrollIntoView>
        )}
      </LazyHero>
      <VisibilitySensor onChange={props.toggleNavDark}>
        <div
          style={{
            width: '100%',
            height: '1px',
            color: 'transparent',
            opacity: 1,
            backgroundColor: 'transparent',
            marginTop: '-1px'
          }}
        >
          .
        </div>
      </VisibilitySensor>
    </div>
  );
};

Hero.propTypes = {
  toggleNavDark: PropTypes.func.isRequired,
  opacity: PropTypes.number,
  video: PropTypes.string,
  'data-aos': PropTypes.string,
  color: PropTypes.string,
  minHeight: PropTypes.string,
  heroImage: PropTypes.string,
  heroImageDefault: PropTypes.string,
  heading1: PropTypes.string,
  heading1Image: PropTypes.string,
  pageImages: PropTypes.arrayOf(PropTypes.any),
  heading1Object: PropTypes.objectOf(PropTypes.any),
  heading1CustomCSS: PropTypes.objectOf(PropTypes.any),
  heading1ImageCSS: PropTypes.objectOf(PropTypes.any),
  'heading1-data-aos': PropTypes.string,
  'heading1-data-aos-offset': PropTypes.string,
  'heading1-data-aos-delay': PropTypes.string,
  'heading1-data-aos-duration': PropTypes.string,
  'heading1-data-aos-easing': PropTypes.string,
  'heading1-data-aos-mirror': PropTypes.bool,
  'heading1-data-aos-once': PropTypes.bool,
  'heading1-data-aos-anchor-placement': PropTypes.string,
  'heading1Image-data-aos': PropTypes.string,
  'heading1Image-data-aos-offset': PropTypes.string,
  'heading1Image-data-aos-delay': PropTypes.string,
  'heading1Image-data-aos-duration': PropTypes.string,
  'heading1Image-data-aos-easing': PropTypes.string,
  'heading1Image-data-aos-mirror': PropTypes.bool,
  'heading1Image-data-aos-once': PropTypes.bool,
  'heading1Image-data-aos-anchor-placement': PropTypes.string,
  heading2Underline: PropTypes.string,
  heading2CustomCSS: PropTypes.objectOf(PropTypes.any),
  'heading2-data-aos': PropTypes.string,
  'heading2-data-aos-offset': PropTypes.string,
  'heading2-data-aos-delay': PropTypes.string,
  'heading2-data-aos-duration': PropTypes.string,
  'heading2-data-aos-easing': PropTypes.string,
  'heading2-data-aos-mirror': PropTypes.bool,
  'heading2-data-aos-once': PropTypes.bool,
  'heading2-data-aos-anchor-placement': PropTypes.string,
  heading3CustomCSS: PropTypes.objectOf(PropTypes.any),
  heading2: PropTypes.string,
  heading3: PropTypes.string,
  'heading3-data-aos': PropTypes.string,
  'heading3-data-aos-offset': PropTypes.string,
  'heading3-data-aos-delay': PropTypes.string,
  'heading3-data-aos-duration': PropTypes.string,
  'heading3-data-aos-easing': PropTypes.string,
  'heading3-data-aos-mirror': PropTypes.bool,
  'heading3-data-aos-once': PropTypes.bool,
  'heading3-data-aos-anchor-placement': PropTypes.string,
  heading4: PropTypes.string,
  heading4CustomCSS: PropTypes.objectOf(PropTypes.any),
  'heading4-data-aos': PropTypes.string,
  'heading4-data-aos-offset': PropTypes.string,
  'heading4-data-aos-delay': PropTypes.string,
  'heading4-data-aos-duration': PropTypes.string,
  'heading4-data-aos-easing': PropTypes.string,
  'heading4-data-aos-mirror': PropTypes.bool,
  'heading4-data-aos-once': PropTypes.bool,
  'heading4-data-aos-anchor-placement': PropTypes.string,
  heading5: PropTypes.string,
  transitionDuration: PropTypes.number,
  transitionTimingFunction: PropTypes.string,
  showScrollDown: PropTypes.bool
};

Hero.defaultProps = {
  'data-aos': undefined,
  transitionTimingFunction: 'ease-in-out',
  transitionDuration: 100,
  minHeight: '65vh',
  color: '#fff',
  video: undefined,
  heroImage: undefined,
  heroImageDefault: defaultHero,
  heading1: undefined,
  pageImages: undefined,
  heading1Image: undefined,
  heading1Object: undefined,
  heading1CustomCSS: undefined,
  heading1ImageCSS: undefined,
  'heading1-data-aos': undefined,
  'heading1-data-aos-offset': '120',
  'heading1-data-aos-delay': '0',
  'heading1-data-aos-duration': '700',
  'heading1-data-aos-easing': 'ease',
  'heading1-data-aos-mirror': false,
  'heading1-data-aos-once': false,
  'heading1-data-aos-anchor-placement': 'top-bottom',
  'heading1Image-data-aos': undefined,
  'heading1Image-data-aos-offset': '120',
  'heading1Image-data-aos-delay': '0',
  'heading1Image-data-aos-duration': '700',
  'heading1Image-data-aos-easing': 'ease',
  'heading1Image-data-aos-mirror': false,
  'heading1Image-data-aos-once': false,
  'heading1Image-data-aos-anchor-placement': 'top-bottom',
  heading2CustomCSS: undefined,
  'heading2-data-aos': undefined,
  'heading2-data-aos-offset': '120',
  'heading2-data-aos-delay': '0',
  'heading2-data-aos-duration': '700',
  'heading2-data-aos-easing': 'ease',
  'heading2-data-aos-mirror': false,
  'heading2-data-aos-once': false,
  'heading2-data-aos-anchor-placement': 'top-bottom',
  heading3CustomCSS: undefined,
  'heading3-data-aos': undefined,
  'heading3-data-aos-offset': '120',
  'heading3-data-aos-delay': '0',
  'heading3-data-aos-duration': '700',
  'heading3-data-aos-easing': 'ease',
  'heading3-data-aos-mirror': false,
  'heading3-data-aos-once': false,
  'heading3-data-aos-anchor-placement': 'top-bottom',
  heading4CustomCSS: undefined,
  'heading4-data-aos': undefined,
  'heading4-data-aos-offset': '120',
  'heading4-data-aos-delay': '0',
  'heading4-data-aos-duration': '700',
  'heading4-data-aos-easing': 'ease',
  'heading4-data-aos-mirror': false,
  'heading4-data-aos-once': false,
  'heading4-data-aos-anchor-placement': 'top-bottom',
  heading2Underline: undefined,
  heading2: undefined,
  heading3: undefined,
  heading4: undefined,
  heading5: undefined,
  showScrollDown: false,
  opacity: 0.8
};

export default Hero;
